<template>
  <amf-page id="amf-user"
            :class="'user-page'">

    <template #content>
      <router-view></router-view>
    </template>
  </amf-page>
</template>

<script>
import AmfPage from '@/components/page-templates/AmfPage.vue';

export default {
  name: 'UserMain',
  components: {
    AmfPage,
  },
};
</script>
